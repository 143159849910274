import React from "react";
import "./styles.css";

const Card = props => (
  <div className="Card">
    <p>{props.key}</p>
    <img className="Adimg" src={props.img} alt="Ads"></img>
  </div>
);

/*
  
  
const Card = props => (
    <div className="Card">
        <p>{props.key}</p>
        <p>{props.title}</p>
      <p>{props.url}</p>
        <img className="Adimg" src={props.img} ></img>
    </div>
  )*/

export default Card;
