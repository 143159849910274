import React, { Component } from "react";
import "../App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Results from "../components/Results";

import { setTimeout } from "timers";
import Loader from "../components/Loader";

class Main extends Component {
  constructor() {
    super();

    this.state = {
      loader: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loader: false });
    }, 3000);
  }

  render() {
    if (this.state.loader === true) {
      return <Loader />;
    } else if (this.state.loader === false) {
      return (
        <Router>
          <div>
            {/* <nav>
              <ul>
                <li>
                  <Link to="/"></Link>
                </li>
              </ul>
            </nav> */}

            {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
            <Switch>
              <Route path="/">
                <Results />
              </Route>
            </Switch>
          </div>
        </Router>
      );
    }
  }
}

export default Main;
