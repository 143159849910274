import firebase from "firebase";

var config = {
  apiKey: "AIzaSyAlSruJjaPxqO4gJNKC-mf4HuTjKsaTReE",
  authDomain: "genawaresurvey.firebaseapp.com",
  databaseURL: "https://genawaresurvey.firebaseio.com",
  projectId: "genawaresurvey",
  storageBucket: "genawaresurvey.appspot.com",
  messagingSenderId: "668850082780",
  appId: "1:668850082780:web:e53d3704aa272baa56b519",
  measurementId: "G-S67B06JRLY"
};

const fire = firebase.initializeApp(config);

export default fire;
