import React from "react";
import ReactLoading from "react-loading";

import { setTimeout } from "timers";

const divStyle = {
  widht: "100%",
  height: "100%"
};

const loaderStyle = {
  margin: " 200px auto 50px",
  width: "150px"
};

const h1Style = {
  width: "100%",
  textAlign: "center"
};

const div2Style = {
  width: "80%",
  margin: "auto"
};

const progressStyle = {
  width: "100%",
  textAlign: "center"
};

export default class Loader extends React.Component {
  constructor() {
    super();

    this.state = {
      message: "Gathering Results",
      value: 10
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ message: "Processing Results", value: 70 });
    }, 1000);

    setTimeout(() => {
      this.setState({ message: "Presenting Results", value: 100 });
    }, 2000);
  }

  render() {
    return (
      <div style={divStyle}>
        <ReactLoading
          style={loaderStyle}
          type={"spin"}
          color={"#000"}
          height={"20%"}
          width={"20%"}
        />
        <h1 style={h1Style}>{this.state.message}</h1>
        <div style={div2Style}>
          <progress
            style={progressStyle}
            value={this.state.value}
            max="100"
          ></progress>
        </div>
      </div>
    );
  }
}
