import React from "react";
import "./App.css";

import Main from "./components/Main";
import { PDFExport } from "@progress/kendo-react-pdf";
import "./components/styles.css";
import Card from "./components/Card";
import fire from "./fire";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      display: "none",
      array: []
    };
  }
  exportPDF = () => {
    this.resume.save();
  };

  componentWillMount() {
    setTimeout(() => {
      this.showPDFButton();
    }, 10000);

    const db = fire.firestore();

    let content = db.collection("content").doc("ads");

    content
      .get()
      .then(doc => {
        if (!doc.exists) {
        } else {
          var test = Object.entries(doc.data());
          this.setState({ array: test });
          console.log(test);
        }
      })
      .catch(err => {
        // console.log("Error getting document", err);
      });
  }

  showPDFButton() {
    this.setState({ display: "block" });
  }

  render() {
    var data = this.state.array;
    return (
      <div>
        <PDFExport
          scale={0.6}
          id="pdfexport"
          paperSize={"Letter"}
          fileName="YourGenAwareResults.pdf"
          title=""
          subject=""
          keywords=""
          ref={r => (this.resume = r)}
          margin={{ top: 10, left: 10, right: 10, bottom: 10 }}
          keepTogether=".mindset"
        >
          <Main />
        </PDFExport>

        <button
          className="downloadPDF"
          style={{ display: this.state.display }}
          onClick={this.exportPDF}
        >
          Download PDF
        </button>
        <div>
          <div className="CardGroup">
            {data.map(i => {
              return (
                <div key={i[0]}>
                  <a
                    key={i[0]}
                    href={i[1][2]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Card title={i[1][1]} url={i[1][2]} img={i[1][3]}></Card>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
