import React from "react";
import ReactHtmlParser from "react-html-parser";

import "./styles.css";

import background from "../images/background.png";

export default class Processor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boomers: 0,
      boomerTotal: 0,
      xer: 0,
      xerTotal: 0,
      millenial: 0,
      millenialTotal: 0,
      genz: 0,
      genzTotal: 0,
      answer1: "",
      mindset: "",
      color: null,
      bar1: "none",
      bar2: "none",
      bar3: "none",
      bar4: "none",
    };
  }

  componentWillMount() {
    this.setState({ answer1: this.props.answer1 });
    this.calculate2();

    setTimeout(() => {
      this.calculate3();
    }, 1000);

    setTimeout(() => {
      this.calculate4();
    }, 1500);

    setTimeout(() => {
      this.calculate5();
    }, 2000);

    setTimeout(() => {
      this.calculate6();
    }, 2500);

    setTimeout(() => {
      this.calculate7();
    }, 3000);

    setTimeout(() => {
      this.calculate8();
    }, 3500);

    setTimeout(() => {
      this.calculate9();
    }, 4000);

    setTimeout(() => {
      this.calculate10();
    }, 4500);

    setTimeout(() => {
      this.calculate11();
    }, 5000);

    setTimeout(() => {
      this.calculate12();
    }, 5500);

    setTimeout(() => {
      this.calculate13();
    }, 6000);

    setTimeout(() => {
      this.calculate14();
    }, 6500);

    setTimeout(() => {
      this.calculateTotal();
    }, 7000);
    /*
     */
  }

  calculate2() {
    // Process Question 2
    console.log("running  2");
    if (this.props.answer2 === `Optimistic- “things will work out”`) {
      this.setState({
        boomers: this.state.boomers + 1,
      });
    } else if (this.props.answer2 === "Skeptical") {
      this.setState({
        xer: this.state.xer + 1,
      });
    } else if (this.props.answer2 === "Idealistic") {
      this.setState({
        millenial: this.state.millenial + 1,
      });
    } else if (this.props.answer2 === "An opportunity to make a difference") {
      this.setState({
        genz: this.state.genz + 1,
      });
    } else {
      console.log("2 Failed");
    }
  }

  calculate3() {
    // Process Question 3
    console.log("running 3");
    if (
      this.props.answer3 === "I feel a sense of loyal obligation to my company."
    ) {
      this.setState({
        boomers: this.state.boomers + 1,
      });
    } else if (
      this.props.answer3 ===
      "I consider myself a ‘free agent’, capable of making different choices should I so desire."
    ) {
      this.setState({ xer: this.state.xer + 1 });
    } else if (
      this.props.answer3 ===
      "If the company is not helping me achieve my goals, I look elsewhere."
    ) {
      this.setState({
        millenial: this.state.millenial + 1,
      });
    } else if (
      this.props.answer3 ===
      "I look for ways to create opportunity (either within the company or on my own)."
    ) {
      this.setState({
        genz: this.state.genz + 1,
      });
    } else {
      console.log("3 Failed");
    }
  }

  calculate4() {
    console.log("running 4");
    if (this.props.answer4 === "If I have it, I typically spend it.") {
      this.setState({ boomers: this.state.boomers + 1 });
    } else if (
      this.props.answer4 ===
      "If I want something and don’t have the money, I’ll use credit to buy it."
    ) {
      this.setState({
        xer: this.state.xer + 1,
      });
    } else if (
      this.props.answer4 ===
      "I tend to look for ways to get more for less / cut costs."
    ) {
      this.setState({
        millenial: this.state.millenial + 1,
      });
    } else if (
      this.props.answer4 ===
      "I seek multiple ways to make money, and educate myself before I make a decision to buy something."
    ) {
      this.setState({
        genz: this.state.genz + 1,
      });
    } else {
      console.log("4 Failed");
    }
  }

  calculate5() {
    console.log("running 5");
    if (this.props.answer5 === "You have to ‘pay your ‘dues’.") {
      this.setState({ boomers: this.state.boomers + 1 });
    } else if (
      this.props.answer5 === "Promotions should be based on competence."
    ) {
      this.setState({
        xer: this.state.xer + 1,
      });
    } else if (
      this.props.answer5 === "We’re all equal- everyone deserves a shot."
    ) {
      this.setState({
        millenial: this.state.millenial + 1,
      });
    } else if (this.props.answer5 === "Collaboration with others is the key.") {
      this.setState({
        genz: this.state.genz + 1,
      });
    } else {
      console.log("5 Failed");
    }
  }

  calculate6() {
    console.log("running 6");
    if (
      this.props.answer6 ===
      "Who I am has a lot to do with what I do for a living."
    ) {
      this.setState({
        boomers: this.state.boomers + 1,
      });
    } else if (
      this.props.answer6 ===
      "I work to support my family, and so that I can do things outside of work that I want to do."
    ) {
      this.setState({
        xer: this.state.xer + 1,
      });
    } else if (
      this.props.answer6 ===
      "My work must provide me with a sense of purpose. Hours at work and outside of work must be balanced."
    ) {
      this.setState({
        millenial: this.state.millenial + 1,
      });
    } else if (this.props.answer6 === "My work and my life are integrated.") {
      this.setState({
        genz: this.state.genz + 1,
      });
    } else {
      console.log("6 Failed");
    }
  }

  calculate7() {
    console.log("running 7");
    if (this.props.answer7 === "Newspapers and/or TV") {
      this.setState({ boomers: this.state.boomers + 1 });
    } else if (this.props.answer7 === "Cable News and/or internet news sites") {
      this.setState({
        xer: this.state.xer + 1,
      });
    } else if (
      this.props.answer7 === "News and Information Channels on the internet"
    ) {
      this.setState({ millenial: this.state.millenial + 1 });
    } else if (
      this.props.answer7 === "Social media / social media influencers"
    ) {
      this.setState({
        genz: this.state.genz + 1,
      });
    } else {
      console.log("7 Failed");
    }
  }
  calculate8() {
    console.log("running 8");
    if (this.props.answer8 === "Newspapers and/or TV") {
      this.setState({
        boomers: this.state.boomers + 1,
      });
    } else if (this.props.answer8 === "TV or computer (internet)") {
      this.setState({
        xer: this.state.xer + 1,
      });
    } else if (this.props.answer8 === "Computer or tablet") {
      this.setState({
        millenial: this.state.millenial + 1,
      });
    } else if (this.props.answer8 === "smartphone") {
      this.setState({
        genz: this.state.genz + 1,
      });
    } else {
      console.log("8 Failed");
    }
  }

  calculate9() {
    console.log("running 9");
    if (this.props.answer9 === " I steer my own course.") {
      this.setState({
        boomers: this.state.boomers + 1,
      });
    } else if (
      this.props.answer9 ===
      "I acknowledge others while relying upon myself first."
    ) {
      this.setState({
        xer: this.state.xer + 1,
      });
    } else if (this.props.answer9 === "We should depend upon one another.") {
      this.setState({
        millenial: this.state.millenial + 1,
      });
    } else if (
      this.props.answer9 ===
      "We have collective responsibility and opportunity."
    ) {
      this.setState({
        genz: this.state.genz + 1,
      });
    } else {
      console.log("9 Failed");
    }
  }

  calculate10() {
    console.log("running 10");
    if (
      this.props.answer10 === "I’m getting a paycheck, so I do as I’m told."
    ) {
      this.setState({
        boomers: this.state.boomers + 1,
      });
    } else if (
      this.props.answer10 ===
      "There are things I don’t like, but I’m hesitant to ‘rock the boat’."
    ) {
      this.setState({
        xer: this.state.xer + 1,
      });
    } else if (
      this.props.answer10 ===
      "Rules should be amended and adapted to be inclusive (of me and others)."
    ) {
      this.setState({
        millenial: this.state.millenial + 1,
      });
    } else if (
      this.props.answer10 ===
      "I see existing ‘rules’ as an opportunity to create positive change by helping make them better."
    ) {
      this.setState({
        genz: this.state.genz + 1,
      });
    } else {
      console.log("10 Failed");
    }
  }

  calculate11() {
    console.log("running 11");
    if (this.props.answer11 === "Annual review") {
      this.setState({
        boomers: this.state.boomers + 1,
      });
    } else if (this.props.answer11 === "Quarterly review") {
      this.setState({
        xer: this.state.xer + 1,
      });
    } else if (this.props.answer11 === "Regular meetings (monthly or weekly)") {
      this.setState({
        millenial: this.state.millenial + 1,
      });
    } else if (this.props.answer11 === "Weekly or Daily") {
      this.setState({
        genz: this.state.genz + 1,
      });
    } else {
      console.log("11 Failed");
    }
  }
  calculate12() {
    console.log("running 12");
    if (this.props.answer12 === "In charge- someone I feel safe to follow") {
      this.setState({
        boomers: this.state.boomers + 1,
      });
    } else if (this.props.answer12 === "Capable and open to being questioned") {
      this.setState({
        xer: this.state.xer + 1,
      });
    } else if (this.props.answer12 === "My coach") {
      this.setState({
        millenial: this.state.millenial + 1,
      });
    } else if (this.props.answer12 === "My mentor") {
      this.setState({
        genz: this.state.genz + 1,
      });
    } else {
      console.log("12 Failed");
    }
  }

  calculate13() {
    console.log("running 13");
    if (this.props.answer13 === "My job is an extension of myself") {
      this.setState({
        boomers: this.state.boomers + 1,
      });
    } else if (this.props.answer13 === "Security") {
      this.setState({
        xer: this.state.xer + 1,
      });
    } else if (
      this.props.answer13 === "It’s an opportunity to grow and develop"
    ) {
      this.setState({
        millenial: this.state.millenial + 1,
      });
    } else if (
      this.props.answer13 ===
      "It’s my opportunity to contribute- to make things better"
    ) {
      this.setState({
        genz: this.state.genz + 1,
      });
    } else {
      console.log("13 Failed");
    }
  }

  calculate14() {
    console.log("running 14");
    if (this.props.answer14 === "Call a meeting with those involved") {
      this.setState({
        boomers: this.state.boomers + 1,
      });

      console.log(this.state.boomers);
    } else if (this.props.answer14 === "Email") {
      this.setState({
        xer: this.state.xer + 1,
      });
    } else if (this.props.answer14 === "Text") {
      this.setState({
        millenial: this.state.millenial + 1,
      });
    } else if (this.props.answer14 === "Video chat") {
      this.setState({
        genz: this.state.genz + 1,
      });
    } else {
      console.log("14 Failed");
    }
  }

  calculateTotal() {
    this.setState({
      boomerTotal: this.state.boomers,
      xerTotal: this.state.xer,
      millenialTotal: this.state.millenial,
      genzTotal: this.state.genz,
    });

    console.log(
      this.state.boomerTotal,
      this.state.xerTotal,
      this.state.millenialTotal,
      this.state.genzTotal,
      this.props.birth
    );

    // Boomer By Mindset Start

    if (this.state.boomerTotal >= 7 && this.props.birth === "Boomer") {
      console.log("Boomer Prevails");

      this.setState({
        mindset: `<p class="western" style="text-align: left;">Generational &lsquo;personality&rsquo; tends to come from significant cultural tenor and economic climate during the years in which you grew up, combined with the economic climate.</p>
        <p class="western" style="text-align: left;">Your <em>age</em> and <em>mindset</em> tend to align.</p>
        <p class="western" style="text-align: left;">While you likely do not align with all of these, typical <strong>Boomer</strong> traits tend to include:</p>
        <ul>
        <li style="text-align: left;">have a generally optimistic outlook</li>
        <li style="text-align: left;">feel a sense of loyalty toward your employer and are generally willing to &lsquo;do what you&rsquo;re told&rsquo;</li>
        <li style="text-align: left;">see your boss as an authority figure</li>
        <li style="text-align: left;">be a financial spender (vice saver)</li>
        <li style="text-align: left;">feel it necessary to &lsquo;pay your dues&rsquo; to achieve promotions and positions of greater authority</li>
        <li style="text-align: left;">&lsquo;live to work&rsquo;, and see your job as an extension of &lsquo;you&rsquo; (it plays significantly into your concept of who you are)</li>
        <li style="text-align: left;">use TV and newspapers as your primary sources of information</li>
        <li style="text-align: left;">be relatively self-centered</li>
        <li style="text-align: left;">be satisfied with an annual review of your performance at work</li>
        </ul>`,
        color: "#d6a8ec",
      });

      console.log(this.state.color);
    } else if (this.state.boomerTotal >= 7) {
      console.log("Thinks Like Boomer");

      this.setState({
        mindset: `<p class="western" style="text-align: left;">Your <em>age</em> and <em>mindset</em> do not match.</p>
        <p class="western" style="text-align: left;">You identify most with Boomer</p>
        <p class="western" style="text-align: left;">This can be the result of many factors including significant events in your personal life, as well as biases of your parents and other significant influencers over the course of your life.</p>
        <p class="western" style="text-align: left;">If you happen to be born toward the end of one generation or beginning of the next (on the &lsquo;cusp&rsquo;), you may also be what&rsquo;s known as a &lsquo;cusper&rsquo;. This can impact your generational <em>mindset</em> as well.</p>
        <p class="western" style="text-align: left;">While you likely do not align with all of these, typical <strong>Boomer</strong> traits tend to include:</p>
        <ul>
        <li style="text-align: left;">have a generally optimistic outlook</li>
        <li style="text-align: left;">feel a sense of loyalty toward your employer and are generally willing to &lsquo;do what you&rsquo;re told&rsquo;</li>
        <li style="text-align: left;">see your boss as an authority figure</li>
        <li style="text-align: left;">be a financial spender (vice saver)</li>
        <li style="text-align: left;">feel it necessary to &lsquo;pay your dues&rsquo; to achieve promotions and positions of greater authority</li>
        <li style="text-align: left;">&lsquo;live to work&rsquo;, and see your job as an extension of &lsquo;you&rsquo; (it plays significantly into your concept of who you are)</li>
        <li style="text-align: left;">use TV and newspapers as your primary sources of information</li>
        <li style="text-align: left;">be relatively self-centered</li>
        <li style="text-align: left;">be satisfied with an annual review of your performance at work</li>
        </ul>`,
        color: "#d6a8ec",
      });

      console.log(this.state.color);
    }

    // GenX By Mindser Start
    else if (this.state.xerTotal >= 7 && this.props.birth === "GenX") {
      console.log("Gen X Prevails");

      this.setState({
        mindset: `<p class="western" style="text-align: left;">Generational &lsquo;personality&rsquo; tends to come from significant cultural tenor and economic climate during the years in which you grew up, combined with the economic climate.</p>
        <p class="western" style="text-align: left;">Your <em>age</em> and <em>mindset</em> tend to align.</p>
        <p class="western" style="text-align: left;">While you likely do not align with all of these, typical <strong>Gen X</strong> traits tend to include:</p>
        <ul>
        <li style="text-align: left;">have a generally skeptical outlook; however, prefer not to &lsquo;rock the boat&rsquo;</li>
        <li style="text-align: left;">see yourself as a &lsquo;free agent&rsquo; when it comes to employment</li>
        <li style="text-align: left;">trust your boss, but often seek to verify choices, actions, and behaviors</li>
        <li style="text-align: left;">be willing to go into debt to acquire what you want</li>
        <li style="text-align: left;">feel that promotions at work should be based upon competence</li>
        <li style="text-align: left;">&lsquo;work to live&rsquo;, primarily seeing your job as a source of security</li>
        <li style="text-align: left;">prefer quarterly reviews for feedback on the job</li>
        <li style="text-align: left;">use TV and the internet as your primary sources of information</li>
        <li style="text-align: left;">be relatively self-reliant</li>
        </ul>`,
        color: " #eaa8a7",
      });
      console.log(this.state.color);
    } else if (this.state.xerTotal >= 7) {
      console.log("Thinks Like Gen X");

      this.setState({
        mindset: `<p class="western" style="text-align: left;">Your <em>age</em> and <em>mindset</em> do not match.</p>
        <p class="western" style="text-align: left;">You identify most with Gen X</p>
        <p class="western" style="text-align: left;">This can be the result of many factors including significant events in your personal life, as well as biases of your parents and other significant influencers over the course of your life.</p>
        <p class="western" style="text-align: left;">If you happen to be born toward the end of one generation or beginning of the next (on the &lsquo;cusp&rsquo;), you may also be what&rsquo;s known as a &lsquo;cusper&rsquo;. This can impact your generational <em>mindset</em> as well.</p>
        <p class="western" style="text-align: left;">While you likely do not align with all of these, typical <strong>Gen X</strong> traits tend to include:</p>
        <ul>
        <li style="text-align: left;">have a generally skeptical outlook; however, prefer not to &lsquo;rock the boat&rsquo;</li>
        <li style="text-align: left;">see yourself as a &lsquo;free agent&rsquo; when it comes to employment</li>
        <li style="text-align: left;">trust your boss, but often seek to verify choices, actions, and behaviors</li>
        <li style="text-align: left;">be willing to go into debt to acquire what you want</li>
        <li style="text-align: left;">feel that promotions at work should be based upon competence</li>
        <li style="text-align: left;">&lsquo;work to live&rsquo;, primarily seeing your job as a source of security</li>
        <li style="text-align: left;">prefer quarterly reviews for feedback on the job</li>
        <li style="text-align: left;">use TV and the internet as your primary sources of information</li>
        <li style="text-align: left;">be relatively self-reliant</li>
        </ul>`,
        color: " #eaa8a7",
      });
      console.log(this.state.color);
    }
    // Millenial By Mindset Start
    else if (
      this.state.millenialTotal >= 7 &&
      this.props.birth === "Millenial"
    ) {
      console.log("Millenial Prevails");

      this.setState({
        mindset: `<p class="western" style="text-align: left;">Generational &lsquo;personality&rsquo; tends to come from significant cultural tenor and economic climate during the years in which you grew up, combined with the economic climate.</p>
        <p class="western" style="text-align: left;">Your <em>age</em> and <em>mindset</em> tend to align.</p>
        <p class="western" style="text-align: left;">While you likely do not align with all of these, typical <strong>Millennial</strong> traits tend to include:</p>
        <ul>
        <li style="text-align: left;">have an idealistic outlook, and expect those in senior positions to adapt to you</li>
        <li style="text-align: left;">be goal oriented, especially when it comes to your job</li>
        <li style="text-align: left;">[want to] see your boss as a coach- one who is there to help you achieve your goals</li>
        <li style="text-align: left;">seek ways to cut costs when it comes to spending money</li>
        <li style="text-align: left;">be &lsquo;egalitarian&rsquo;, feeling all are equal and deserve equal privilege and respect</li>
        <li style="text-align: left;">feel that your work must provide a sense of purpose (often beyond a simple &lsquo;paycheck&rsquo;)</li>
        <li style="text-align: left;">prefer regular meetings with your supervisor (coach) for feedback on the job</li>
        <li style="text-align: left;">use the internet as your primary source of information</li>
        <li style="text-align: left;">place more focus on the &lsquo;collective self&rsquo; rather than the individual</li>
        </ul>`,
        color: "#a9ccea",
      });
      console.log(this.state.color);
    } else if (this.state.millenialTotal >= 7) {
      this.setState({
        mindset: `<p class="western" style="text-align: left;">Your <em>age</em> and <em>mindset</em> do not match.</p>
        <p class="western" style="text-align: left;">You identify most with Millennials</p>
        <p class="western" style="text-align: left;">This can be the result of many factors including significant events in your personal life, as well as biases of your parents and other significant influencers over the course of your life.</p>
        <p class="western" style="text-align: left;">If you happen to be born toward the end of one generation or beginning of the next (on the &lsquo;cusp&rsquo;), you may also be what&rsquo;s known as a &lsquo;cusper&rsquo;. This can impact your generational <em>mindset</em> as well.</p>
        <p class="western" style="text-align: left;">While you likely do not align with all of these, typical <strong>Millennial</strong> traits tend to include:</p>
        <ul>
        <li style="text-align: left;">have an idealistic outlook, and expect those in senior positions to adapt to you</li>
        <li style="text-align: left;">be goal oriented, especially when it comes to your job</li>
        <li style="text-align: left;">[want to] see your boss as a coach- one who is there to help you achieve your goals</li>
        <li style="text-align: left;">seek ways to cut costs when it comes to spending money</li>
        <li style="text-align: left;">be &lsquo;egalitarian&rsquo;, feeling all are equal and deserve equal privilege and respect</li>
        <li style="text-align: left;">feel that your work must provide a sense of purpose (often beyond a simple &lsquo;paycheck&rsquo;)</li>
        <li style="text-align: left;">prefer regular meetings with your supervisor (coach) for feedback on the job</li>
        <li style="text-align: left;">use the internet as your primary source of information</li>
        <li style="text-align: left;">place more focus on the &lsquo;collective self&rsquo; rather than the individual</li>
        </ul>`,
        color: "#a9ccea",
      });
      console.log(this.state.color);
    }

    // GenZ By Mindset Start
    else if (this.state.genzTotal >= 7 && this.props.birth === "GenZ") {
      console.log("Gen Z Prevails");

      this.setState({
        mindset: `<p class="western" style="text-align: left;">Generational &lsquo;personality&rsquo; tends to come from significant cultural tenor and economic climate during the years in which you grew up, combined with the economic climate.</p>
        <p class="western" style="text-align: left;">Your <em>age</em> and <em>mindset</em> tend to align.</p>
        <p class="western" style="text-align: left;">While you likely do not align with all of these, typical <strong>Gen Z</strong> traits tend to include:</p>
        <ul>
        <li style="text-align: left;">see opportunities to make a difference</li>
        <li style="text-align: left;">approach work as an entrepreneur/intrapreneur</li>
        <li style="text-align: left;">[want to] have a boss/supervisor who is your mentor</li>
        <li style="text-align: left;">seek multiple sources of income and educate yourself before making a &lsquo;buy&rsquo; decision</li>
        <li style="text-align: left;">see work as a collaborative opportunity to create change</li>
        <li style="text-align: left;">see work as an integrated aspect of your life</li>
        <li style="text-align: left;">prefer daily or weekly feedback on the job</li>
        <li style="text-align: left;">use social media and your favorite internet influencers as your primary source of information</li>
        <li style="text-align: left;">be oriented toward a collective opportunity to contribute and make things better</li>
        </ul>`,
        color: "#a5e7c3",
      });
      console.log(this.state.color);
    } else if (this.state.genzTotal >= 7) {
      this.setState({
        mindset: `<p class="western" style="text-align: left;">Your <em>age</em> and <em>mindset</em> do not match.</p>
        <p class="western" style="text-align: left;">You identify most with Gen Z</p>
        <p class="western" style="text-align: left;">This can be the result of many factors including significant events in your personal life, as well as biases of your parents and other significant influencers over the course of your life.</p>
        <p class="western" style="text-align: left;">If you happen to be born toward the end of one generation or beginning of the next (on the &lsquo;cusp&rsquo;), you may also be what's known as a &lsquo;cusper&rsquo;. This can impact your generational <em>mindset</em> as well.</p>
        <p class="western" style="text-align: left;"><a name="_GoBack"></a> While you likely do not align with all of these, typical <strong>Gen Z</strong> traits tend to include:</p>
        <ul>
        <li style="text-align: left;">see opportunities to make a difference</li>
        <li style="text-align: left;">approach work as an entrepreneur/intrapreneur</li>
        <li style="text-align: left;">[want to] have a boss/supervisor who is your mentor</li>
        <li style="text-align: left;">seek multiple sources of income and educate yourself before making a &lsquo;buy&rsquo; decision</li>
        <li style="text-align: left;">see work as a collaborative opportunity to create change</li>
        <li style="text-align: left;">see work as an integrated aspect of your life</li>
        <li style="text-align: left;">prefer daily or weekly feedback on the job</li>
        <li style="text-align: left;">use social media and your favorite internet influencers as your primary source of information</li>
        <li style="text-align: left;">be oriented toward a collective opportunity to contribute and make things better</li>
        </ul>`,
        color: "#a5e7c3",
      });
      console.log(this.state.color);
    } else {
      this.setState({
        mindset: `<p>You tend to have a mindset capturing characteristics of multiple generations.</p>
        <p>This can be a great asset in the workplace, opening opportunities for you to be more aware and empathetic when it comes to working with team members from different generations.</p>
        <p>You can use your score and relate it to the table below to see where your mindset falls relative to each generation.</p>
        <p>&nbsp;</p>`,
      });
    }
  }

  render() {
    console.log(this.state.color);

    return (
      <div>
        <div>
          <div
            style={{ backgroundColor: this.props.color }}
            className="answer1"
          >
            <h1>{this.props.title}</h1>
            {ReactHtmlParser(this.state.answer1)}
          </div>
        </div>

        <div className="mindset" style={{ backgroundColor: this.state.color }}>
          <h1>My Generational Mindset</h1>
          <div>{ReactHtmlParser(this.state.mindset)}</div>
        </div>

        <div className="Custom" style={{ backgroundImage: "url('" }}>
          <div
            style={{
              height: this.state.boomers * 42,
              width: 50,
              backgroundColor: "#8e5db0",
            }}
          >
            <h1 className="Value">{this.state.boomers}</h1>
          </div>
          <div
            style={{
              height: this.state.xer * 42,
              width: 50,
              backgroundColor: "#fe1a01",
            }}
          >
            <h1 className="Value">{this.state.xer}</h1>
          </div>

          <div
            style={{
              height: this.state.millenial * 42,
              width: 50,
              backgroundColor: "#0070c0",
            }}
          >
            <h1 className="Value">{this.state.millenial}</h1>
          </div>
          <div
            style={{
              height: this.state.genz * 42,
              width: 50,
              backgroundColor: "#02af50",
            }}
          >
            <h1 className="Value">{this.state.genz}</h1>
          </div>
        </div>
        <div className="Legend">
          <h1>B</h1>
          <h1>X</h1>
          <h1>M</h1>
          <h1>Z</h1>
        </div>
      </div>
    );
  }
}
