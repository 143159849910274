import React, { Component } from "react";

import fire from "../fire";
import Processor from "./Processor";
import "./styles.css";
import logo from "../images/logo.png";

const Boomer = `<p class="western" style="text-align: left;">Based upon your birth year, you are a member of the <em>Boomer Generation</em>.</p>
<p class="western" style="text-align: left;">Chronologically, this generation is made up of those born between 1946 and 1964.</p>
<p class="western" style="text-align: left;">The term &ldquo;baby boomer&rdquo; was first used in a January 1963 article in the <em>Daily Press</em> to describe a massive surge of college enrollments as the oldest boomers began graduating high school.</p>
<p class="western" style="text-align: left;">The term is more fondly known for the &ldquo;boom&rdquo; of babies born after soldiers returned home to raise families following WWII.</p>
<p class="western" style="text-align: left;">As a <em>Boomer</em>, the overall &lsquo;personality&rsquo; of your generation has been impacted by the cultural tenor and economic climate, combined with significant social and political events occurring during the years in which you grew up.</p>
<p class="western" style="text-align: left;">During your &lsquo;growing up&rsquo; years, the economy predominately prospered. The 1950s and first half of the 60s brought forth a rise in the standard of living for many and significant expansion of the middle class.</p>
<p class="western" style="text-align: left;">The cultural tenor was predominately that of opportunity and possibility. While some women began to work outside the home, most were &lsquo;stay-at-home&rsquo; Moms.</p>
<p class="western" style="text-align: left;">Significant social and political events occurring while you grew up included:</p>
<ul>
<li style="text-align: left;">The Korean War (1950-1953)</li>
<li style="text-align: left;">Russian launch of Sputnik (1957)</li>
<li style="text-align: left;">The Cold War (fear of nuclear attack) (1947-1991)</li>
<li style="text-align: left;">US Civil Rights movement (early 60s)</li>
<li style="text-align: left;">The first Walmart and Kmart stores open (1962)</li>
<li style="text-align: left;">President Kennedy commitment to &ldquo;Go to the Moon&rdquo; (1962) (&lsquo;Space Age&rsquo; begins)</li>
<li style="text-align: left;">Martin Luther King Jr. delivers &ldquo;I Have a Dream&hellip;&rdquo; (1963)</li>
<li style="text-align: left;">Assassination of President John F. Kennedy (1963)</li>
<li style="text-align: left;">US Civil Rights Act passed (1964)</li>
<li style="text-align: left;">The Viet Nam War (1955-1975) (brought into homes during the evening news in living color)</li>
<li style="text-align: left;">Demonstrations /Riots on US college campuses (early 60s to 1970)</li>
<li style="text-align: left;">Assassination of Martin Luther King Jr. (April, 1968)</li>
<li style="text-align: left;">Assassination of Robert F. Kennedy (June, 1968)</li>
<li style="text-align: left;">Neil Armstrong &ldquo;One Small Step for Man&rdquo; (1969)</li>
<li style="text-align: left;">&lsquo;Burning&rsquo; of Detroit (1970)</li>
<li style="text-align: left;">Kent State University Shootings (1970)</li>
</ul>
`;
const GenX = `<p class="western" style="text-align: left;">Based upon your birth year, you are a member of G<em>eneration</em> <em>X</em>.</p>
<p class="western" style="text-align: left;">Chronologically, this generation is made up of those born between 1965 and 1980.</p>
<p class="western" style="text-align: left;">The term &ldquo;Generation X&rdquo; was first used by photographer Robert Capa as a title for a photo essay of young adults in the 1950s, but it didn&rsquo;t &lsquo;stick&rsquo;. The name for your generation comes from a 1965 book titled <em>Generation X, </em>and was further anchored by a 1991 book entitled <em>Generation X: Tales for an Accelerated Culture.</em></p>
<p class="western" style="text-align: left;">As a member of <em>Gen X, </em>the overall &lsquo;personality&rsquo; of your generation has been impacted by the cultural tenor and economic climate, combined with significant social and political events occurring during the years in which you grew up.</p>
<p class="western" style="text-align: left;">During your &lsquo;growing up&rsquo; years, the cultural landscape shifted from optimism and prosperity to skepticism and concern.</p>
<p class="western" style="text-align: left;">Mothers entered the workforce in large numbers. Divorces skyrocketed. Many in your generation came home from school to empty houses, resulting in many from this generation being raised as &lsquo;latchkey kids&rsquo;<em>.</em></p>
<p class="western" style="text-align: left;">Significant social, political, and economic events occurring while you grew up included:</p>
<ul>
<li style="text-align: left;">The Watergate Scandal (1972)</li>
<li style="text-align: left;">Terrorism at the Munich Olympics (1972)</li>
<li style="text-align: left;">The Arab oil embargo (1973)</li>
<li style="text-align: left;">President Nixon resignation (1974)</li>
<li style="text-align: left;">End of the Viet Nam War (1975)</li>
<li style="text-align: left;">Rise of the personal computer (1977)</li>
<li style="text-align: left;">Three Mile Island Nuclear Meltdown (1979)</li>
<li style="text-align: left;">US inflation rate reaches 18 percent (1980)</li>
<li style="text-align: left;">Iranian hostage crisis (1979-1981)</li>
<li style="text-align: left;">Rise of corporate layoffs (early 80s)</li>
<li style="text-align: left;">Challenger Disaster (1986)</li>
<li style="text-align: left;">Stock market &ldquo;Black Friday&rdquo; (1987)</li>
<li style="text-align: left;">Exxon Valdez Oil Tanker Spill (1989)</li>
<li style="text-align: left;">Rise of video games (Nintendo- 1989)</li>
<li style="text-align: left;">Chernobyl Nuclear Meltdown (1987)</li>
<li style="text-align: left;">Operation Desert Storm (1990)</li>
<li style="text-align: left;">Dissolution of the Soviet Union (1991)</li>
</ul>`;

const Millenial = `<p class="western" style="text-align: left;">Based upon your birth year, you are a member of the <em>Millennial Generation</em>.</p>
<p class="western" style="text-align: left;">Chronologically, this generation is made up of those born between 1981 and 1996.</p>
<p class="western" style="text-align: left;">Originally referred to as &ldquo;Generation Y&rdquo;, the term <em>Millennials </em>was coined in 1987 by authors William Strauss and Neil Howe. At the time, children born in 1982 were entering kindergarten, setting them up as the high school graduating class of 2000 (thus the name).</p>
<p class="western" style="text-align: left;">Some researchers use the year 2000 as the end point for &ldquo;Millennials&rdquo;. However, 1996, which encompasses the typical 18-year span for generational studies and statistics, is more widely used.</p>
<p class="western" style="text-align: left;">As a <em>Millennial</em>, the overall &lsquo;personality&rsquo; of your generation has been impacted by the cultural tenor and economic climate, combined with significant social and political events occurring during the years in which you grew up.</p>
<p class="western" style="text-align: left;">During your &lsquo;growing up&rsquo; years, the world changed dramatically with the accelerating emergence of technology and proliferation of the internet. You and your generational cohorts are considered &ldquo;tech capable&rdquo; since you learned to adapt and use technology as it developed.</p>
<p class="western" style="text-align: left;">Social media became part of life while the &lsquo;war on terror&rsquo; and schoolyard violence took the front pages.</p>
<p class="western" style="text-align: left;">Getting an [expensive] college degree became the expectation, coincident with the rise of &ldquo;girl power&rdquo;. The percentage of females enrolling in 4-year and postgraduate studies increased substantially, with women becoming the secondary education majority in 1988.</p>
<p class="western" style="text-align: left;">The cost of college increased 3 to 4 times the inflation rate, leaving many in your generation with substantial college debt- at a time when economic conditions prompted delayed retirements of those already working while &lsquo;real&rsquo; wages have been seen as &lsquo;shrinking&rsquo;.</p>
<p class="western" style="text-align: left;">Significant social, political, and economic events occurring while you grew up included:</p>
<ul>
<li style="text-align: left;">&ldquo;Helicopter Parents&rdquo; proliferate (term coined in 1990)</li>
<li style="text-align: left;">Operation Desert Storm (1991)</li>
<li style="text-align: left;">UN Framework on &ldquo;climate change&rdquo; (1992)</li>
<li style="text-align: left;">Netscape and Yahoo! come on the scene (1994-1995)</li>
<li style="text-align: left;">Oklahoma City bombing (1995)</li>
<li style="text-align: left;">Rise of the Blackberry (2000)</li>
<li style="text-align: left;">Rise of &ldquo;Reality TV&rdquo; (2000)</li>
<li style="text-align: left;">Terrorist Airline Hijackings and Suicide Attacks (09/11/2001)</li>
<li style="text-align: left;">War(s) in middle east (2001-present)</li>
<li style="text-align: left;">Social Media Development</li>
<li style="text-align: left;">MySpace (2003)</li>
<li style="text-align: left;">Facebook (2004)</li>
<li style="text-align: left;">YouTube (2005)</li>
<li style="text-align: left;">Google became the #1 search engine (2004)</li>
<li style="text-align: left;">Hurricane Katrina (2005)</li>
<li style="text-align: left;">Columbine High School shooting (2009)</li>
<li style="text-align: left;">Student debt rises by 116% (2009-2019)</li>
<li style="text-align: left;">US economic &ldquo;middle class&rdquo; shrinks by 4% (1991-2016)</li>
</ul>`;

const GenZ = `<p class="western" style="text-align: left;">Chronologically, this generation is made up of those born after 1996.</p>
<p class="western" style="text-align: left;">Your generation is known as &ldquo;Generation Z&rdquo; because it follows &ldquo;Generation X&rdquo; and &ldquo;Generation Y&rdquo; (now known as the &ldquo;Millennials&rdquo;). Other names have been applied such as &ldquo;iGen&rdquo; and &ldquo;Digitals&rdquo;; however, at present, it appears &ldquo;Gen Z&rdquo; will stick.</p>
<p class="western" style="text-align: left;">Data is still being accumulated regarding your formative years, which are still in progress.</p>
<p class="western" style="text-align: left;">As a member of <em>Gen Z</em>, the overall &lsquo;personality&rsquo; of your generation continues to be impacted by the cultural tenor and economic climate, combined with significant social and political events occurring during your &lsquo;growing up&rsquo; years.</p>
<p class="western" style="text-align: left;">Thus far, your growing-up years have included the &lsquo;Great Recession&rsquo;, followed by the longest stock market boom and lowest US unemployment rates since 1944.</p>
<p class="western" style="text-align: left;">Parents are waiting longer (on average 5 years) before having children, and are having fewer children. Much of this is likely due to the high costs of raising children, combined with the perception that a college degree is a prerequisite to achieving a middle-class standard of living.</p>
<p class="western" style="text-align: left;">Population growth in the US has been fastest among &lsquo;minorities&rsquo;. In 2012, 50.4% of American children under the age of 1 belonged to racial and ethnic minority groups.</p>
<p class="western" style="text-align: left;">Having experienced technology often [literally] from the crib, you and fellow members of your generation are considered &ldquo;tech dependent.&rdquo; Nearly 100% of your generation own a smartphone, and spend (on average) more than 4 hours per day online.</p>
<p class="western" style="text-align: left;">Significant social, political, and economic events occurring thus far during your &lsquo;formative years&rsquo; have included:</p>
<ul>
<li style="text-align: left;">War(s) in middle east (2001-present)</li>
<li style="text-align: left;">Rise of the &lsquo;Blogosphere&rsquo; and online influencers (50 million blogs by mid-2006)</li>
<li style="text-align: left;">The &lsquo;Great Recession&rsquo; (2007-2009)</li>
<li style="text-align: left;">Rise of the smartphone (2008)</li>
<li style="text-align: left;">China becomes second-largest economy in the world (2010)</li>
<li style="text-align: left;">Creation of Instagram (2010)</li>
<li style="text-align: left;">Creation of Snapchat (2011)</li>
<li style="text-align: left;">US unemployment rate drops below 5% and continues to lower (2016-present)</li>
</ul>`;

export default class Results extends Component {
  constructor() {
    super();

    this.state = {
      email: "",
      name: "",
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      answer5: "",
      answer6: "",
      answer7: "",
      answer8: "",
      answer9: "",
      answer10: "",
      answer11: "",
      answer12: "",
      answer13: "",
      answer14: "",
      boomer: 0,
      xer: 0,
      millennial: 0,
      genz: 0,
    };
  }

  componentDidMount() {
    //GET URL ADDRESS - Start
    const url = window.location.href;

    var email = url.substring(url.lastIndexOf("?") + 1);

    var db = fire.firestore();

    let ref = db.collection("root");

    let allDocs = ref
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          if (doc.id === email) {
            console.log("MATCH");
            console.log(doc.id, "=>", doc.data());

            this.setState({
              email: doc.data().Email,
              name: doc.data().Name,
              answer1: doc.data().Answer001,
              answer2: doc.data().Answer002,
              answer3: doc.data().Answer003,
              answer4: doc.data().Answer004,
              answer5: doc.data().Answer005,
              answer6: doc.data().Answer006,
              answer7: doc.data().Answer007,
              answer8: doc.data().Answer008,
              answer9: doc.data().Answer009,
              answer10: doc.data().Answer010,
              answer11: doc.data().Answer011,
              answer12: doc.data().Answer012,
              answer13: doc.data().Answer013,
              answer14: doc.data().Answer014,
            });

            console.log(
              this.state.email,
              this.state.name,
              this.state.answer1,
              this.state.answer2,
              this.state.answer3,
              this.state.answer4,
              this.state.answer5,
              this.state.answer6,
              this.state.answer7,
              this.state.answer8,
              this.state.answer9,
              this.state.answer10,
              this.state.answer11,
              this.state.answer12,
              this.state.answer13,
              this.state.answer14
            );
          } else {
            console.log("No Match");
          }
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });

    console.log(allDocs);
  }

  render() {
    console.log(this.state.answer2);
    //Question 1 Process - Start
    if (this.state.answer1 === "1946-1964") {
      return (
        <div className="App">
          <img alt="logo" className="logo" src={logo}></img>
          <Processor
            title="My Generation by Birth is Boomer"
            birth={"Boomer"}
            answer1={Boomer}
            answer2={this.state.answer2}
            answer3={this.state.answer3}
            answer4={this.state.answer4}
            answer5={this.state.answer5}
            answer6={this.state.answer6}
            answer7={this.state.answer7}
            answer8={this.state.answer8}
            answer9={this.state.answer9}
            answer10={this.state.answer10}
            answer11={this.state.answer11}
            answer12={this.state.answer12}
            answer13={this.state.answer13}
            answer14={this.state.answer14}
            color="#d1b1d4"
          />
        </div>
      );
    } else if (this.state.answer1 === "1965-1982") {
      return (
        <div className="App">
          <img alt="logo" className="logo" src={logo}></img>
          <Processor
            title="My Generation by Birth is Gen X"
            birth={"GenX"}
            answer1={GenX}
            answer2={this.state.answer2}
            answer3={this.state.answer3}
            answer4={this.state.answer4}
            answer5={this.state.answer5}
            answer6={this.state.answer6}
            answer7={this.state.answer7}
            answer8={this.state.answer8}
            answer9={this.state.answer9}
            answer10={this.state.answer10}
            answer11={this.state.answer11}
            answer12={this.state.answer12}
            answer13={this.state.answer13}
            answer14={this.state.answer14}
            color="#eaa8a7"
          />
        </div>
      );
    } else if (this.state.answer1 === "1983-1996") {
      return (
        <div className="App">
          <img alt="logo" className="logo" src={logo}></img>
          <Processor
            title="My Generation by Birth is Millenial"
            birth={"Millenial"}
            answer1={Millenial}
            answer2={this.state.answer2}
            answer3={this.state.answer3}
            answer4={this.state.answer4}
            answer5={this.state.answer5}
            answer6={this.state.answer6}
            answer7={this.state.answer7}
            answer8={this.state.answer8}
            answer9={this.state.answer9}
            answer10={this.state.answer10}
            answer11={this.state.answer11}
            answer12={this.state.answer12}
            answer13={this.state.answer13}
            answer14={this.state.answer14}
            color="#a9ccea"
          />
        </div>
      );
    } else if (this.state.answer1 === "After 1996") {
      return (
        <div className="App">
          <img alt="logo" className="logo" src={logo}></img>

          <Processor
            title="My Generation by Birth is Gen Z"
            birth={"GenZ"}
            answer1={GenZ}
            answer2={this.state.answer2}
            answer3={this.state.answer3}
            answer4={this.state.answer4}
            answer5={this.state.answer5}
            answer6={this.state.answer6}
            answer7={this.state.answer7}
            answer8={this.state.answer8}
            answer9={this.state.answer9}
            answer10={this.state.answer10}
            answer11={this.state.answer11}
            answer12={this.state.answer12}
            answer13={this.state.answer13}
            answer14={this.state.answer14}
            color="#a5e7c3"
          />
        </div>
      );

      //Question 1 Process - End
    } else {
      return (
        <div className="App">
          <h1>Loading</h1>
        </div>
      );
    }
  }
}
